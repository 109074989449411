// 核心优势
<template>
  <div class="service-hxys">
    <el-row class="hxys-list">
      <el-col class="hxys-item flex-center" :span="24" v-for="item in hxyss" :key="item.id">
        <div class="hxys-item-num" :style="`background-image:url(${$base+item.content})`"></div>
        <div class="hxys-item-info ">
          <h2 class="info-title">{{item.name}}</h2>
          <p class="info-text text-ellipsis-lines">{{item.describe}}</p>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getCore } from '@/api'
export default {
  name: 'CoreStrengths',
  data () {
    return {
      hxyss: []
    };
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      // 核心优势
      try {
        let hxysRes = await getCore('hxys')
        this.hxyss = hxysRes.data
      } catch (error) {
        console.log("网络错误");
      }
    }
  },
};
</script>

<style scoped lang="less">
.hxys-item {
  margin-bottom: 23px;
  .hxys-item-num {
    width: 279px;
    height: 166px;
    border-radius: 83px;
  }
  .hxys-item-info {
    flex: 1;
    height: 192px;
    border: 2px solid #fbd690;
    border-radius: 96px;
    margin-left: -76px;
    background-color: #fff;
    padding: 0 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .info-title {
      font-size: 30px;
      letter-spacing: 2px;
      margin-bottom: 12px;
    }
    .info-text {
      letter-spacing: 1px;
      line-height: 30px;
    }
  }
}
</style>
